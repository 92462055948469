/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
import dayjs, { type Dayjs } from 'dayjs';
import { type ImpersonateData, type UserData } from '../types';
import {
  type PaginatediReportPermissionDto, type PowerBiReportPermissionDto, type SSRSReportPermissionDto, type TreeComponent
} from '../views/roles/api/types';
import { type BiHubPipelineTaskRunLogDto, StatusTypesMap } from '../views/bi-hub/api/types';
import { RefreshBroadcastChannelName, RefreshBroadcastMessage } from './Consts';
import AppNotification from './notifications/AppNotifications';

export const getUserData = () => {
  const userDataObject: UserData = JSON.parse(localStorage.getItem('userData')!);
  return userDataObject;
};

export const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;

export const getImpersonateData = () => {
  const impersonateDataObject: ImpersonateData = JSON.parse(localStorage.getItem('impersonateData')!);
  return impersonateDataObject;
};

export const getUserPermissions = () => localStorage.getItem('permissions');

export const getTwoFactorRememberClientToken = () => {
  const rememberClientToken: string = localStorage.getItem('twoFactorRememberClientToken')!;
  return rememberClientToken;
};

export const refreshBrowserTabs = () => {
  if (typeof BroadcastChannel === 'undefined') {
    console.warn('BroadcastChannel is not supported in this browser.');
    return;
  }

  const broadcastChannel = new BroadcastChannel(RefreshBroadcastChannelName);
  broadcastChannel.postMessage(RefreshBroadcastMessage);
  window.dispatchEvent(new Event('storage'));
};

export function loadScript(url: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  document.body.appendChild(script);
}

export const convertDataTypeParameter = (dataType: number): ('String' | 'Numeric' | 'Date' | 'Integer') => {
  switch (dataType) {
    case 1:
      return 'String';
    case 2:
      return 'Date';
    case 3:
      return 'Numeric';
    case 4:
      return 'Integer';
    default:
      return 'String';
  }
};

export const convertDataTypeParameterToHtmlInput = (dataType: number): ('text' | 'date' | 'number') => {
  switch (dataType) {
    case 1:
      return 'text';
    case 2:
      return 'date';
    case 3:
      return 'number';
    case 4:
      return 'number';
    default:
      return 'text';
  }
};

export function randStringPrefix(prefix: string) {
  return Math.random().toString(36).replace('0.', prefix || '');
}

interface HasId {
  id: number;
}

export function extractIds<T extends HasId>(objects: T[]): number[] {
  return objects.map((obj) => obj.id);
}

export function extractSpecificParameter(objects: any, objString: string): number[] {
  return objects.map((obj: any) => obj[objString]);
}

type AnyObject = Record<string, string | any>;

export function replaceEmptyStrings(obj: AnyObject): AnyObject {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key].trim() === '') {
      obj[key] = null;
    }
  }

  // Refactored from above, but still reassigns params which it shouldn't
  // Object.keys(obj).forEach((value, iteration) => {
  //   if (typeof obj[iteration] === 'string' && obj[iteration].trim() === '') {
  //     obj[iteration] = null;
  //   }
  // });

  return obj;
}

export const makeListSelectReady = (options: string[] | number[]) => options.map((option) => ({
  value: option,
  label: option.toString(),
}));

export const makeObjectSelectReady = (
  options: any[],
  valueKey: string,
  labelKey: string
) => options.map((option) => ({
  ...option,
  value: option[valueKey],
  label: option[labelKey],
}));

// Utility to replace path params in a given URL template
// Input:  replacePathParams(V1HostBiHubDetail.BaseRequest.url, { tenantId: 123 })
// Outputs: /api/v1/host/BiHub/123
export function replacePathParams<Params extends Record<string, string | number>>(
  urlTemplate: string,
  params: Params
): string {
  let result = urlTemplate;
  Object.entries(params).forEach((iteration) => {
    const placeholder = `{${iteration[0]}}`;
    result = result.replace(placeholder, iteration[1].toString());
  });

  return result;
}

// Utility to add params as params to URL template
// Input: setUrlParams(V1HostLogsReportEmbedLogsList.BaseRequest.url, { tenantId: 123 })
// Output: /api/v1/host/Logs/ReportEmbedLogs?tenantId=123&
export function setUrlParams(urlTemplate: string, params: {}) {
  if (Object.keys(params).length === 0 || !params) return urlTemplate;
  let paramString: string = '';
  Object.entries(params).forEach((iteration) => {
    paramString += `${iteration[0]}=${iteration[1]}&`;
  });

  return `${urlTemplate}?${paramString}`;
}

export const hasDuplicates = (fields: any, errorMessageTitle: string) => {
  const seen = new Set();
  return fields.some((currentObject: any) => {
    const test = seen.size === seen.add(currentObject.columnIdentifier).size;
    if (test) {
      AppNotification.error(errorMessageTitle, `Duplicate column identifier: ${currentObject.columnIdentifier}`);
      return currentObject.columnIdentifier;
    }
  });
};

export const formatTree = (
  arr: any[] | undefined,
  childrenKey: string,
  idKey: string,
  nameKey: string
) => {
  const tree: TreeComponent[] = [];
  arr!.forEach((element) => {
    let childTree: TreeComponent[] = [];

    // Check if the element has children
    if (element[childrenKey]) {
      // If the element has children, call the formatDocumentTree function for each child
      element[childrenKey].forEach((child: any) => {
        childTree = [...childTree, ...formatTree([child], childrenKey, idKey, nameKey)];
      });
    }

    const component: TreeComponent = {
      key: element[idKey].toString(),
      title: element[nameKey],
      children: childTree,
    };

    tree.push(component);
  });
  return tree;
};

export const getSelectedPermissions = (
  arr: any[],
  childrenKey: string,
  checkedKey: string,
  nameKey: string
) => {
  const result: string[] = [];

  arr.forEach((item: any) => {
    if (item[checkedKey]) result.push(item[nameKey].toString());
    if (item[childrenKey]) {
      result.push(...getSelectedPermissions(
        item[childrenKey],
        childrenKey,
        checkedKey,
        nameKey
      ));
    }
  });

  return result;
};

export function transformPowerBiInputToOutput(powerBiReports: PowerBiReportPermissionDto[]) {
  return powerBiReports
    .filter((report) => report.checked)
    .map((report) => ({
      reportId: report.id,
      parameters: report.powerBiReportParameters
        ? report.powerBiReportParameters
          .filter((parameter) => parameter.checked)
          .map((parameter) => ({
            parameterId: parameter.id,
            values: parameter.parameterValues
              ? parameter.parameterValues
                .filter((parameterValue) => parameterValue.checked)
                .map((parameterValue) => parameterValue.id)
              : [],
          }))
        : [],
    }));
}

export function transformPaginatedInputToOutput(powerBiReports: PaginatediReportPermissionDto[]) {
  return powerBiReports
    .filter((report) => report.checked)
    .map((report) => ({
      reportId: report.id,
      parameters: report.paginatedReportParameters
        ? report.paginatedReportParameters
          .filter((parameter) => parameter.checked)
          .map((parameter) => ({
            parameterId: parameter.id,
            values: parameter.parameterValues
              ? parameter.parameterValues
                .filter((parameterValue) => parameterValue.checked)
                .map((parameterValue) => parameterValue.id)
              : [],
          }))
        : [],
    }));
}

export function transformSSRSInputToOutput(SSRSReport: SSRSReportPermissionDto[]) {
  return SSRSReport
    .filter((report) => report.checked)
    .map((report) => ({
      reportId: report.id,
      parameters: report.ssrsReportParameters
        ? report.ssrsReportParameters
          .filter((parameter) => parameter.checked)
          .map((parameter) => ({
            parameterId: parameter.id,
            values: parameter.parameterValues
              ? parameter.parameterValues
                .filter((parameterValue) => parameterValue.checked)
                .map((parameterValue) => parameterValue.id)
              : [],
          }))
        : [],
    }));
}

export const parametersFormatTree = (
  permissions: any[],
  idString: string,
  keyName: string,
  childrenkey: string
) => {
  const tree: TreeComponent[] = [];
  permissions.forEach((element) => {
    let childTree: TreeComponent[] = [];

    if (element[childrenkey]) {
      element[childrenkey].forEach((child: any) => {
        childTree = [...childTree, ...parametersFormatTree([child], 'parameterId', keyName, childrenkey)];
      });
    }

    const component: TreeComponent = {
      key: element[idString],
      title: element[keyName],
      children: childTree,
    };

    tree.push(component);
  });
  return tree;
};

export const bytesToMegaBytes = (
  bytes: number,
  digits: number,
  roundTo?: number
) => (roundTo ? (bytes / (1024 * 1024)).toFixed(digits) : (bytes / (1024 * 1024)));

export function objectByString(o: any, s: string) {
  try {
    if (!s) return;
    s = s.replace(/\[(\w+)]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  } catch {

  }
}

export function shouldDisableLogButton(pipelineTaskRun: BiHubPipelineTaskRunLogDto) {
  // If task is started or NotStarted disable the button
  if (pipelineTaskRun.status === 0 || pipelineTaskRun.status === 1) { return true; }

  if (pipelineTaskRun.type === 6) { return true; }

  return false;
}

export function getStatusColor(status: number): string {
  const statusText: string | undefined = StatusTypesMap.get(status);

  if (statusText === 'NotStarted' || statusText === 'FinishedWithError') {
    return 'bg-warning text-dark';
  } if (statusText === 'Error' || statusText === 'HubCommunicationFailed' || statusText === 'Timeout') {
    return 'bg-danger';
  } if (statusText === 'Started') {
    return 'bg-info';
  }
  return 'bg-success';
}

export const convertToSeconds = (datetime: Dayjs | null | undefined): number => {
  if (!datetime) return 0;

  const hours: number = datetime.hour();
  const minutes: number = datetime.minute();

  return (hours * 60 * 60) + (minutes * 60);
};

export function secondsToDayjs(totalSeconds: number): Dayjs {
  const secondsInADay = 24 * 60 * 60;
  const days: number = Math.floor(totalSeconds / secondsInADay);
  const remainingSeconds: number = totalSeconds % secondsInADay;

  // Constructing Day.js object
  const date = dayjs().startOf('day').add(days, 'day').add(remainingSeconds, 'second');

  return date;
}
