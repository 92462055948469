import { type TenantDto } from '../../../api/data-contracts';
import { makeListSelectReady } from '../../../utility/Utils';
import AppCheckInput from '../../components/forms/AppCheckInput';
import AppFormDropdownAnt from '../../components/forms/AppFormDropdownAnt';
import { useGetDimColumnOptionsQuery } from '../api/api';

interface Props {
  values: TenantDto;
  databaseList: string[];
}

function BiSpecificSettings({ values, databaseList, }: Props) {
  const showBiSettings = values.editionId!.toString() === '1';

  const { data, isFetching, } = useGetDimColumnOptionsQuery({ databaseName: values.databaseName ?? '', }, {
    skip: !showBiSettings || !values.databaseName,
  });

  if (!showBiSettings) { return <></>; }

  return (
    <>
      <div className='fv-row mb-5'>
        <AppFormDropdownAnt
          name='databaseName'
          options={makeListSelectReady(databaseList)}
          label='Select database'
          required={true} loading={false} />
      </div>

      <div className="separator mb-4" />

      <h4>Mapping settings</h4>

      <div className="fv-row my-3">
        <AppCheckInput name="isMappingEnabled" label="Enable mapping for host" />
      </div>

      <div className="fv-row mb-5">
        <AppFormDropdownAnt
          mode="multiple"
          disabled={!values.isMappingEnabled}
          loading={isFetching}
          name="excludedTenantMappingColumnsList"
          options={data ?? []}
          label="Filter out columns for the company"
        />
      </div>

      <div className="separator mb-4" />
    </>
  );
}

export default BiSpecificSettings;
