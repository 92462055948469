import { type CreateEditTenantDto, type Tenant, type TenantOptions } from './Types';
import { emptySplitApi } from '../../../utility/emptySplitApi';
import { PowerBiApi_Host_Route, Tenant_Common_Route, Tenant_Host_Route } from '../../../constants/routes';
import { type GetRequestFilter } from '../../../types';
import { Api } from '../../../api/ApiRoute';
import { makeListSelectReady, replacePathParams } from '../../../utility/Utils';
import { type CreateTenantRequestDto, type DimMappingChangeLogDtoPagedResultWithFiltersDtoResult, type PatchTenantStatusDto, type UpdateTenantRequestDto } from '../../../api/data-contracts';

const tenantApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getTenants: build.query({
      query: (input: Api.V1HostTenantsPagedList.RequestQuery) => ({
        url: Api.V1HostTenantsPagedList.BaseRequest.url,
        method: Api.V1HostTenantsPagedList.BaseRequest.method,
        params: input,
      }),
      transformResponse: (e: DimMappingChangeLogDtoPagedResultWithFiltersDtoResult) => e.data,
      providesTags: ['Tenant'],
    }),

    getTenantById: build.query({
      query: (id) => Tenant_Host_Route + id,
      transformResponse: (rawResult: { data: CreateEditTenantDto }) => rawResult.data,
      providesTags: ['MigrateReport', 'Tenant'],
    }),

    getTenantLogoByAccessToken: build.query({
      query: () => ({
        url: `${Tenant_Common_Route}logo`,
        responseHandler: async (response) => await response.text(),
      }),
      providesTags: ['TenantLogo'],
    }),

    getTenantLogo: build.query({
      query: (id) => ({
        url: `${Tenant_Common_Route}logo?tenantId=${id}`,
        responseHandler: async (response) => await response.text(),
      }),
      providesTags: ['TenantLogo'],
    }),

    addNewTenant: build.mutation({
      query(data: CreateTenantRequestDto) {
        return {
          url: Tenant_Host_Route,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (rawResult: { data: Tenant }) => {
        return rawResult.data;
      },
      invalidatesTags: ['Tenant'],
    }),

    uploadLogo: build.mutation({
      query(data: FormData) {
        return {
          url: `${Tenant_Host_Route}UploadLogo`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['TenantLogo'],
    }),

    EditTenant: build.mutation({
      query(data: UpdateTenantRequestDto) {
        return {
          url: Tenant_Host_Route,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Tenant'],
    }),

    PatchTenantStatus: build.mutation({
      query(payload: PatchTenantStatusDto) {
        return {
          url: Api.V1HostTenantsSetTenantStatusPartialUpdate.BaseRequest.url,
          method: Api.V1HostTenantsSetTenantStatusPartialUpdate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: ['Tenant'],
    }),

    GetTenantOptions: build.query({
      query: () => `${Tenant_Host_Route}TenantsOptions`,
      transformResponse: (
        rawResult: {
          data: {
            editions: Array<{
              id: number;
              name: string;
            }>
            ; databases: string[];
          };
        }
      ) => {
        const tempMap = new Map<number, string>();
        rawResult.data.editions.forEach((item: { id: number; name: string }) => {
          tempMap.set(item.id, item.name);
        });

        return { editions: tempMap, databases: rawResult.data.databases, } as TenantOptions;
      },
    }),

    GetDimColumnOptions: build.query({
      query: (input: Api.V1HostTenantsDimColumnOptionsDetail.PathParams) => ({
        url: replacePathParams(Api.V1HostTenantsDimColumnOptionsDetail.BaseRequest.url, input),
        method: Api.V1HostTenantsDimColumnOptionsDetail.BaseRequest.method,
      }),
      transformResponse: (rawResult: string[]) => makeListSelectReady(rawResult),
    }),

    GetTenantUtilisation: build.query({
      query: (input: GetRequestFilter) => ({
        url: `${PowerBiApi_Host_Route}TenantUtilization`,
        method: 'GET',
        params: input,
      }),
      providesTags: ['Tenant'],
    }),

    DeleteTenant: build.mutation({
      query: (arg: Api.V1HostTenantsDelete.PathParams) => ({
        url: replacePathParams(Api.V1HostTenantsDelete.BaseRequest.url, arg),
        method: Api.V1HostTenantsDelete.BaseRequest.method,
      }),
      invalidatesTags: ['Tenant'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTenantByIdQuery,
  useGetTenantsQuery,
  useAddNewTenantMutation,
  useEditTenantMutation,
  useGetTenantUtilisationQuery,
  useGetTenantLogoByAccessTokenQuery,
  useDeleteTenantMutation,
  useUploadLogoMutation,
  useGetTenantLogoQuery,
  useGetTenantOptionsQuery,
  useGetDimColumnOptionsQuery,
  usePatchTenantStatusMutation,
} = tenantApi;
