import * as React from 'react';
import { useFormikContext } from 'formik';
import AppFormLabel from './AppFormLabel';
import AppFormError from './AppFormError';
import { objectByString } from '../../../utility/Utils';

interface AppTextInputProps {
    name: string;
    label?: string;
    required?: boolean;
    optionsMap?: Map<number, string>;
    optionsList?: string[];
    secondName?: string;
    onChange?: (event: number | string) => void;
}

function AppFormDropdown ({
  optionsMap,
  optionsList,
  name,
  label,
  secondName,
  required = false,
  onChange,
}: AppTextInputProps) {
  const { setFieldValue, errors, values, } = useFormikContext();

  if (optionsMap) {
    return (
      <>
        <AppFormLabel label={label} required={required} />
        <select
          className="form-select"
          id="floatingSelect"
          aria-label="Floating label select example"
          value={objectByString(values, name)}
          onChange={(event: React.FormEvent<HTMLSelectElement>) => {
            const selectedOptionKey = Array.from(optionsMap.keys())[
              event.currentTarget.selectedIndex];
            secondName && setFieldValue(secondName, event.currentTarget.selectedIndex);
            setFieldValue(name, selectedOptionKey);
            if (onChange) {
              onChange(selectedOptionKey);
            }
          }}
        >
          {Array.from(optionsMap.entries()).map((entry) => {
            const [key, value] = entry;
            return (
              <option value={key} key={key}>
                {value}
              </option>
            );
          })}
        </select>
        <AppFormError
          visible={objectByString(errors, name)}
          error={objectByString(errors, name)}
        />
      </>
    );
  }
  if (optionsList) {
    return (
      <>
        <AppFormLabel label={label} required={required} />
        <select
          className="form-select"
          id="floatingSelect"
          aria-label="Floating label select example"
          value={objectByString(values, name)}
          onChange={(event: React.FormEvent<HTMLSelectElement>) => {
            setFieldValue(name, optionsList[event.currentTarget.selectedIndex]);
            if (onChange) {
              onChange(optionsList[event.currentTarget.selectedIndex]);
            }
          }}
        >
          {optionsList.map((value) => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
        </select>
        <AppFormError
          visible={objectByString(errors, name)}
          error={objectByString(errors, name)}
        />
      </>
    );
  }
  return null;
}

export default AppFormDropdown;
