import { type Column } from 'react-table';
import { type ImpersonateTenant, type role } from '../../api/Types';
import { ImpersonateActionCell } from '../../components/ImpersonateActionCell';
import { TableBadges } from '../../../components/table/TableBadges';

const impersonateColumns: ReadonlyArray<Column<ImpersonateTenant>> = [
  {
    Header: 'Impersonate',
    id: 'impersonate',
    Cell: ({ ...props }) => (
      <ImpersonateActionCell text="Login" id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Last name',
    accessor: 'surname',
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
  },
  {
    Header: 'Roles',
    accessor: 'roles',
    disableSortBy: true,
    Cell: ({ ...props }) => (
      <TableBadges
        stringList={props.data[props.row.index].roles.map((role: role) => role.roleDisplayName)}
        maxLength={5}
      />
    ),
  }
];

export { impersonateColumns };
